<template>
  <p>Выход</p>
</template>
<script>
export default {
  name: 'Logout',
  created() {
      this.$auth.logout({});
  },
};
</script>